import React, { Component } from "react";
import '../../styles/components/cards/review.scss';

class Review extends Component {
    render() {

        return (this.props.free_check ?
            <div className={`review_card ${this.props.class_name}`}>
                <p>Join hundreds of satisfied customers</p>
                <q>{this.props.text}</q>
                <div className='review_footer'>
                    {/* <img className='avatar' src={`./assets/homepage/avatars/${this.props.avatar}.png`} alt='' /> */}

                    <div id='trustpilot_link'>
                        <a href='https://www.trustpilot.com/review/www.autoregcheck.co.uk' target="_blank" rel="noreferrer">Check out our reviews on Trustpilot</a>
                        <img src='./assets/icons/arrow_right.svg' alt='Arrow pointing right' />
                    </div>

                </div>
            </div> : <></>
        );
    }
}
export default Review;