import "../styles/views/report.scss";
import React, { Component } from "react"
import axios from 'axios';
import { REPORT_DOWN, set_history_status, get_relevant_data, HISTORY_METADATA } from "../utils";
import ReportNav from "../components/ReportNav";
import Header from '../components/Header'
import ReportMobNav from "../components/ReportMobNav";

import Overview from '../components/report_panels/Overview'
import History from '../components/report_panels/History'
import Valuation from '../components/report_panels/Valuation'
import EcoImpact from '../components/report_panels/EcoImpact'
import Certification from '../components/report_panels/Certification'
import Specification from '../components/report_panels/Specification'
import ReportLoad from '../components/report_panels/ReportLoad'
import Breadcrumb from "../components/Breadcrumb";

import { Helmet, HelmetProvider } from 'react-helmet-async';

const panels = {
    over: Overview,
    hist: History,
    cert: Certification,
    valu: Valuation,
    ecoi: EcoImpact,
    spec: Specification,
}

class Report extends Component {

    constructor(props) {
        super(props)
        this.state = {
            panel: "load",
            hist_item: null,
            report_data: [],
            logo: "./assets/logos/NO_LOGO.png",
            plate: "LOADING",
            car_img: "",
            free_check: false,
            visited: [],
            alerts: { hist: 0, cert: 0, eco: 0 },
            action: [0, 'min'],
            details: {
                car: null,
                year: null,
                colour: null,
                fuel_type: null,
                fuel_size: null,
                plate: null,
                logo: null
            }
        }
    }

    componentDidMount() {
        const URL = window.location.href.split('/')
        let ID = URL[URL.length - 1]

        if (window.location.href[window.location.href.length - 1] === '/') {
            ID = URL[URL.length - 2]
        }

        if (this.props.free_check) {
            this.setState({ free_check: true })
            this.setState({ car_img: './assets/car_no_image.webp' })

            if (localStorage.getItem('cookies_accepted') === "accepted") {
                window.hj = window.hj || function () { (window.hj.q = window.hj.q || []).push(arguments) };
                window._hjSettings = { hjid: 3716479, hjsv: 6 };
                var head = document.getElementsByTagName('head')[0];
                var script = document.createElement('script');
                script.async = 1;
                script.src = 'https://static.hotjar.com/c/hotjar-' + window._hjSettings.hjid + '.js?sv=' + window._hjSettings.hjsv;
                head.appendChild(script);
            }
            
        } else {
            if (REPORT_DOWN && ID !== '1234') {
                window.location.href = 'maintenance'
            } else {
                this.setState({ car_img: this.get_image_api_img(ID) })
            }
        }

        this.fetch_data(ID)
    }

    handle_nav = (panel, hist_item) => {

        // How to affect mobile nav scroll
        let full_halves = Math.floor((window.innerWidth / 2) / 38)
        let shift_to_right = Math.ceil(full_halves / 2)
        let shift_to_left = 7 - shift_to_right - 1

        // Index of selected panel
        let selected = Object.keys(panels).indexOf(panel)

        // Center if in the center-able buttons, otherwise either max or min the scroll
        if (shift_to_right <= selected && selected <= shift_to_left) {
            this.setState({ action: [selected, 'centre'] })
        } else if (selected < shift_to_right) {
            this.setState({ action: [selected, 'min'] })
        } else {
            this.setState({ action: [selected, 'max'] })
        }

        if (hist_item === "Tax" || hist_item === "MOT") {
            this.setState({ hist_item: hist_item, panel: 'cert' })
        } else {
            this.setState({ hist_item: hist_item, panel: panel })
        }

        window.scrollTo(0, 0)

    }

    double_check_mileage = (list) => {
        let mile_list_formatted = []
        for (let record of list) {
            mile_list_formatted.push(record['Mileage'])
        }
        mile_list_formatted.reverse()

        let current = 0
        for (let mile of mile_list_formatted) {
            if (mile >= current) {
                current = mile
            } else {
                return true
            }
        }
    }

    set_alerts = (data) => {

        let history_alerts = []
        let mot_alerts = []
        let eco_alerts = []

        if (this.props.free_check) {

            if (data.free_check_results.recall === true) {
                history_alerts.push('Recalls')
            }

            if (data.free_check_results.export === true) {
                history_alerts.push('Exported')
            }

            // MOT
            if (data.free_check_results.mot_valid !== 'pass') {
                mot_alerts.push('MOT & Tax Check')
            }
            if (data.free_check_results.tax_valid !== 'pass') {
                mot_alerts.push('MOT & Tax Check')
            }

            let metadata = get_relevant_data(data.api_metadata, 'C')
            let filtered_data = {}
            for (let m of metadata) {
                m['value'] = data.api_response[m['item_name']]
                filtered_data[m['item_name']] = m
            }
            for (let mot_test of filtered_data['mot_test_list'].value) {
                if (mot_test['FailureReasonList']) {
                    if (mot_test['FailureReasonList'].length > 0) {
                        mot_alerts.push('Full MOT History')
                    }
                }
            }

        } else {

            // HISTORY
            let metadata = get_relevant_data(data.api_metadata, 'H')
            let filtered_data = {}
            for (let m of metadata) {
                m['value'] = data.api_response[m['item_name']]
                filtered_data[m['item_name']] = m
            }

            let history_statuses = set_history_status(filtered_data)
            history_alerts = data.recalls.length > 0 ? ['Recalls'] : []
            for (const key in history_statuses) {
                if (history_statuses.hasOwnProperty(key) && history_statuses[key].status === 'fail') {
                    // Add the item name (full name, not the code)
                    let item = Object.entries(HISTORY_METADATA).filter(([h]) => HISTORY_METADATA[h].item_name === key)[0][1].home_title

                    if (item) {
                        history_alerts.push(item)
                    }
                }
            }

            // MOTs
            metadata = get_relevant_data(data.api_metadata, 'C')
            filtered_data = {}
            for (let m of metadata) {
                m['value'] = data.api_response[m['item_name']]
                filtered_data[m['item_name']] = m
            }
            for (let mot_test of filtered_data['mot_test_list'].value) {
                if (mot_test['FailureReasonList']) {
                    if (mot_test['FailureReasonList'].length > 0) {
                        mot_alerts.push('Full MOT History')
                    }
                }
            }
            if (data.api_response['DVLA_motStatus'] !== 'Valid') {
                mot_alerts.push('MOT & Tax Check')
            }
            if (data.api_response['DVLA_taxStatus'] !== 'Taxed') {
                mot_alerts.push('MOT & Tax Check')
            }

            // ECO
            metadata = get_relevant_data(data.api_metadata, 'E')
            filtered_data = {}
            for (let m of metadata) {
                m['value'] = data.api_response[m['item_name']]
                filtered_data[m['item_name']] = m
            }

            let euro = filtered_data['euro_status'].value
            let fuel = filtered_data['fuel_type'].value
            let year = filtered_data['year'].value

            if (euro && fuel) {
                const PASS = ((fuel === "PETROL" && parseInt(euro.substring(0, 1)) >= 4)) || ((fuel === "DIESEL" && parseInt(euro.substring(0, 1)) >= 6) || (fuel !== "PETROL" && fuel !== "DIESEL"))
                eco_alerts = PASS ? [] : ['LEZ Check', 'LEZ Check', 'LEZ Check', 'LEZ Check', 'LEZ Check', 'LEZ Check', 'LEZ Check', 'LEZ Check']
            } else {
                if (year) {
                    if (parseInt(year) <= 2005) {
                        eco_alerts = ['LEZ Check', 'LEZ Check', 'LEZ Check', 'LEZ Check', 'LEZ Check', 'LEZ Check', 'LEZ Check', 'LEZ Check']
                    }
                }
            }
        }

        this.setState({
            alerts: {
                hist: history_alerts,
                cert: mot_alerts,
                ecoi: eco_alerts,
                valu: [],
                over: [],
                spec: [],
            }
        })
    }

    fetch_data = async (id) => {
        let logo = ""
        let data = []
        let plate = ""

        if (this.props.free_check) {
            try {
                const response = await axios.get(`/api/get-free-check/${id}`);

                if (response.data.length === 0) {
                    window.location.href = '/invalid-plate'
                } else if (response.data.too_new) {
                    window.location.href = '/new-registration'
                } else {
                    logo = `./assets/logos/${response.data.api_response['make'].replace(" ", "").toLowerCase()}.png`
                    if (response.data.api_response['make'] === 'IRIS.BUS') {
                        logo = `./assets/logos/iveco.png`
                    }

                    data = response.data
                    plate = id

                    this.setState({
                        report_data: data,
                        logo: logo,
                        plate: plate,
                        panel: "over"
                    })

                    this.set_CD_state(data)
                }
            }
            catch (error) {
                window.location.href = '/invalid-plate'
            }
        } else {
            try {
                const response = await axios.get(`/api/get-report-data/${id}`);

                logo = `./assets/logos/${response.data.api_response['make'].replace(" ", "").toLowerCase()}.png`
                if (response.data.api_response['make'] === 'IRIS.BUS') {
                    logo = `./assets/logos/iveco.png`
                }

                let mileage_list = response.data.api_response.mileage_list
                if (mileage_list && !response.data.api_response.mileage_anomaly) {
                    response.data.api_response.mileage_anomaly = this.double_check_mileage(mileage_list)
                }

                data = response.data
                plate = `${response.data.report_metadata['plate']}`

                this.setState({
                    report_data: data,
                    logo: logo,
                    plate: plate,
                    panel: "over",
                })
                this.set_CD_state(data)

            } catch (error) {
                window.location.href = '/invalid-report'
            }
        }

        this.set_alerts(data)
    };


    get_image_api_img = (id) => {
        return id === '1234' ? "./assets/car_test_report.webp" : ''
    }

    handleLogoError = (event) => {
        event.target.onError = null; // Prevent infinite loop if fallback image also fails
        event.target.src = "./assets/logos/NO_LOGO.png"
    }

    set_CD_state = (data) => {
        let metadata = get_relevant_data(data.api_metadata, 'O')

        let filtered_data = {}
        for (let m of metadata) {
            m['value'] = data.api_response[m['item_name']]
            filtered_data[m['item_name']] = m
        }

        let logo = `./assets/logos/${filtered_data['make'].value.replace(" ", "").toLowerCase()}.png`
        if (filtered_data['make'].value === 'IRIS.BUS') {
            logo = `./assets/logos/iveco.png`
        }
        const CD = {
            plate: filtered_data['vrm'].value,
            car: `${filtered_data['make'].value} ${filtered_data['model'].value}`,
            year: filtered_data['year'].value ? parseInt(filtered_data['year'].value) : filtered_data['year'].value,
            colour: filtered_data['colour'].value,
            fuel_type: filtered_data['fuel_type'].value,
            fuel_size: filtered_data['engine_size'].value,
            logo: logo,
            co2: data.api_response.co2,
            weight: data.api_response.DVLA_weight,
            car_img: data.free_check_img
        }

        this.setState({ details: CD })
    }

    render() {
        let PanelComponent = this.state.panel === "load" ? ReportLoad : panels[this.state.panel];

        let URL = window.location.href.split('/')
        let ID = URL[URL.length - 1]

        if (window.location.href[window.location.href.length - 1] === '/') {
            ID = URL[URL.length - 2]
        }

        const breadcrumbs = [
            { name: "Home", item: "https://www.autoregcheck.co.uk/" },
            { name: "Sample Report", item: "https://www.autoregcheck.co.uk/report/1234/" }
        ]

        return (
            <HelmetProvider>
                <Helmet>
                    <title>{ID === '1234' ? 'Sample Car Check Report | Auto Reg Check ' : 'Car Check Report | Auto Reg Check '}</title>
                    {ID === '1234' && <meta name="description" content="Take Our Comprehensive Car Check Report For A Spin | Try Out A Sample Car Check Report And Find Out Why Auto Reg Check Has A Trustpilot Rating Of 4.4" />}
                    {ID === '1234' && <link rel="canonical" href="https://www.autoregcheck.co.uk/report/1234/" />}
                </Helmet>

                {ID === '1234' && <Breadcrumb breadcrumbs={breadcrumbs} />}

                <main id="main_report">

                    <Header dark={true} active_nav='report' plate={this.state.plate} />

                    {this.state.panel !== 'load' &&
                        <ReportMobNav
                            active={this.state.panel}
                            handle_nav={this.handle_nav}
                            alerts={this.state.alerts}
                            action={this.state.action}
                        />}

                    {this.state.panel !== 'load' &&
                        <ReportNav
                            active={this.state.panel}
                            handle_nav={this.handle_nav}
                            alerts={this.state.alerts}
                            free_check={this.state.free_check}
                            plate={this.state.plate}
                        />}

                    <section id="section_report" className={this.props.free_check ? 'section_report_free_check' : ''}>

                        <PanelComponent
                            data={this.state.report_data}
                            hist_change={this.handle_nav}
                            hist_check={this.state.hist_item}
                            car_img={this.state.car_img}
                            plate={this.state.plate}
                            free_check={this.state.free_check}
                            details={this.state.details}
                        />

                    </section>




                    <p id='copy'>&copy; 2023 Xegon Automotive. All rights reserved.</p>

                </main>
            </HelmetProvider>
        )
    }
}

export default Report;
